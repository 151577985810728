import { Controller } from "@hotwired/stimulus"
import { useTransition } from "stimulus-use"

export default class ModalFrameController extends Controller {
  static values = { referrer: String }

  connect () {
    useTransition(this, {
      transitioned: this.element.children.length,
      enterFrom:    "modal-frame--enter-from",
      enterTo:      "modal-frame--enter-to",
      leaveFrom:    "modal-frame--leave-from",
      leaveTo:      "modal-frame--leave-to"
    })

    this.element.addEventListener("turbo:frame-render", this.enter)
  }

  disconnect () {
    this.element.removeEventListener("turbo:frame-render", this.enter)
  }

  close = async (event) => {
    if (event) event.preventDefault()

    const closeEvent = this.dispatch("close")
    if (closeEvent.defaultPrevented) return

    await this.leave()

    if (this.hasReferrerValue) {
      Turbo.visit(this.referrerValue, { action: "replace" })
    }

    this.element.replaceChildren()
    this.element.src = null
  }
}
