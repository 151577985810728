import { Controller } from "@hotwired/stimulus"

export default class SwitchFormController extends Controller {
  static targets = ["item", "input"]

  update (event) {
    let currentValues

    if (this.inputTargets.length > 0) {
      currentValues = this.inputTargets.filter((input) => input.checked).map((input) => String(input.value))
    } else {
      currentValues = [String(event.target.value)]
    }

    this.itemTargets.forEach((item) => {
      let expectedValues
      let enabled

      if (item.dataset.switchFormValue !== undefined) {
        expectedValues = [item.dataset.switchFormValue + ""]
      } else if (item.dataset.switchFormValues !== undefined) {
        expectedValues = JSON.parse(item.dataset.switchFormValues).map(String)
      } else {
        return
      }

      enabled = expectedValues.some((v) => currentValues.includes(v))

      item.hidden = !enabled
      item.disabled = !enabled
    })
  }
}
